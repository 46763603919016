import React from "react"
import "./styles/team.scss";

export const Accordion = ({ title, children, onSelect, showContent, category }) => (
  <div>
    <div className={`accordion ${showContent ? "activeAcc" : ""}`}
      onClick={onSelect}>
      <div className="title-wrap">
        <span className={`title ${showContent ? "activeTitle" : ""}`}>{title}</span>
        <span className="category">{category}</span>
      </div>
    </div>
    {showContent ?
      <div className={"accordionContent"}>
        {children}
      </div> : <span />}
  </div>
)