import React, { Component } from "react"
import "./styles/team.scss";

export const MetaData = ({ champ, iconStyle, textStyle, text, type }) => (
  <div className={"metaDiv"}>
    <div className={"champDiv"}>
      {type === 'coffee' ? <img className={iconStyle} src="https://cdn.glitch.com/26046cd4-85e4-403f-9f73-94671d944273%2FCoffee-GIF?v=1585308719374" /> : <span className={iconStyle} />}
      <span className={"champText"}>{champ}</span>
    </div>
    <span className={textStyle}>{text}</span>
  </div>
)