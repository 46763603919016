import React, { useState } from "react"
import "../../src/components/styles/team.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { ResponsiveImage, CrewMember } from "../components/helpers"
import { MetaData } from "../components/metadata"
import { Accordion } from "../components/accordion"
import { useStaticQuery, graphql } from "gatsby"
import ReactMarkdown from 'react-markdown'

const data = {
  heading: "Create Something Original",
  crewDescription: "The makers, builders & \n tinkerers behind Kubric",
  introduction: {
    description:
      "Good design is at the heart of everything around us. We use technology to help designers remove limits from their creativity so they can focus on brewing original ideas and bringing them to life.",
    image: {
      url:
        "https://cdn.glitch.com/26046cd4-85e4-403f-9f73-94671d944273%2Fgroup-14%403x.png?v=1584963449404",
      mobileURL:
        "https://lh3.googleusercontent.com/6cR4p1vrlk7JvlCO3KQ5MNzD_sxNktPIoYXooKra6w4F2irk15mVpXsDAuy-lgYHttxqT-WwmoxkLLIPtKt6kE4",
      alt: "Kubric Team",
    },
    openPositionsLink: "https://angel.co/company/kubric-io",
    teamDes:
      "We’re an indie design-loving and futurists team working out of Bengaluru, Toronto & San-Francisco. \n\nWe are relentlessly focused on pushing the boundaries of good design and using technology to remove the barriers that hold back creative professionals around the world.",
  },
  trivia: [
    {
      type: "ageDemography",
      text: "Age Distribution",
    },
    {
      type: "slackMoji",
      text: "Favourite slackmoji",
    },
    {
      type: "coffee",
      text: "Coffee Variants",
    },
    {
      type: "tournament",
      text: "TT champ of the month",
      champ: "Paroksh",
    },
  ],
  team: [
    {
      name: "Kausambi Manjita",
      job: "Founder (Growth, Experiences)",
      image: {
        url:
          "https://lh3.googleusercontent.com/NYqZqmnzYrJt_A26QuqNPTvCMNZltEzgNT8fY4tdBdF4q8ZDcKSGMUUiz1Fp0goooxWjkmAO7n5rQXWmMrq0o6HO",
        mobileURL:
          "https://lh3.googleusercontent.com/NYqZqmnzYrJt_A26QuqNPTvCMNZltEzgNT8fY4tdBdF4q8ZDcKSGMUUiz1Fp0goooxWjkmAO7n5rQXWmMrq0o6HO",
        alt: "Team Member",
      },
      description:
        "Kaus 💖's bringing great experiences to life. A pro home-chef and a closet wordsmith, she infuses her instinctive “feel” of people and spaces into everything she creates - lovingly, of course.",
      instagram: "https://www.instagram.com/kausambi/?hl=en",
      twitter: "https://twitter.com/kmanjita?lang=en",
      linkedin: "https://www.linkedin.com/in/kmanjita/",
    },
    {
      name: "Barada Sahu",
      job: "Founder (Vision, Products)",
      image: {
        url:
          "https://lh3.googleusercontent.com/p1hbOTJNqsuNYVbpP7ouYK7a3W1tKEk_r6IQmLrPoUyySz5kYRJBP-svKdIeTDA9XxMMVXXVZZYvwLEADz0rYdVJ",
        mobileURL:
          "https://lh3.googleusercontent.com/p1hbOTJNqsuNYVbpP7ouYK7a3W1tKEk_r6IQmLrPoUyySz5kYRJBP-svKdIeTDA9XxMMVXXVZZYvwLEADz0rYdVJ",
        alt: "Team Member",
      },
      description:
        "Meet Barry, connoisseur of effortless products, avant-garde concepts, and mindless memes. He’s the Team Coach at Kubric, regularly charting out maps for unmapped galaxies with flair 🚀",
      linkedin: "https://www.linkedin.com/in/baradasahu/",
      github: "https://github.com/mercurialsolo",
    },
    {
      name: "Jophin Joseph",
      job: "Engineering (Frameworks, Tech)",
      image: {
        url:
          "https://lh3.googleusercontent.com/xKYmJ54GlzlrwCToFrdjnOnKKFAPD1PO3BERB-W62V40z2QxkvXixuy6IXwdM7bgYSqMPeegRXZgFLsA7cBHPhFr",
        mobileURL:
          "https://lh3.googleusercontent.com/xKYmJ54GlzlrwCToFrdjnOnKKFAPD1PO3BERB-W62V40z2QxkvXixuy6IXwdM7bgYSqMPeegRXZgFLsA7cBHPhFr",
        alt: "Team Member",
      },
      description:
        "Jo is all about hardcore history - the one to beat for WWII trivia. On a regular day, you’ll find him deep 🎧in Dan Carlin’s lore while building bleeding-edge UI architecture for scale.",
      github: "https://github.com/ophinjay",
      linkedin: "https://www.linkedin.com/in/jophin-joseph",
    },
    {
      name: "Amit Phadke",
      job: "Growth (Process, Strategy)",
      image: {
        url:
          "https://lh3.googleusercontent.com/w_AYBAte3LVJ0phpYB74Qb-ZnwsPkx5e2ZENvqnFYUJKnqt_v9G2swERMAp-HAB0xKZviYV-pP4oCraj97jZRJk",
        mobileURL:
          "https://lh3.googleusercontent.com/w_AYBAte3LVJ0phpYB74Qb-ZnwsPkx5e2ZENvqnFYUJKnqt_v9G2swERMAp-HAB0xKZviYV-pP4oCraj97jZRJk",
        alt: "Team Member",
      },
      description:
        "Did you say “complex”? 🧩Amit can arrange the meanest and most-stubborn complexities into a neatly solved Rubik’s cube while boiling his half-dozen eggs for breakfast. Just like that.",
      linkedin: "https://www.linkedin.com/in/amit-phadke/",
      twitter: "https://twitter.com/amitphadke750",
      github: "https://github.com/phadkeamit",
    },
    {
      name: "Paroksh Saxena",
      job: "Engineering (People, Tech) ",
      image: {
        url:
          "https://lh3.googleusercontent.com/-ZvqyjJQTY7m0ZGz7z5rCzKEzR3jZA077cK1hclSmXq77SNcpl4SAK0Sb7PxsRHO7_FinqCY2R8uiMQMJiV7qQM",
        mobileURL:
          "https://lh3.googleusercontent.com/-ZvqyjJQTY7m0ZGz7z5rCzKEzR3jZA077cK1hclSmXq77SNcpl4SAK0Sb7PxsRHO7_FinqCY2R8uiMQMJiV7qQM",
        alt: "Team Member",
      },
      description:
        "Paro is the only person we know who plays (and wins) ‘antakshari’🎵with himself. Life of a party, pro coach, ardent food-taster. Did we mention his annual 100KM walk-for-charity🚶‍♂️yet?",
      instagram: "https://www.instagram.com/paroksh.saxena/",
      linkedin: "https://www.linkedin.com/in/paroksh-saxena/",
      github: "https://github.com/parokshsaxena",
      twitter: "https://twitter.com/parokshsaxena",
    },
    {
      name: "Chaitanya Nettem",
      job: "Engineering (Products, People)",
      image: {
        url:
          "https://lh3.googleusercontent.com/rOazkNlIKuvpjwbX_U5XJf9KUncL_913Z6f_e8IVUZz8djVX_955EB0-zlmp1_wKRUhZf-Pf-2MaHWepAMOhM0M",
        mobileURL:
          "https://lh3.googleusercontent.com/rOazkNlIKuvpjwbX_U5XJf9KUncL_913Z6f_e8IVUZz8djVX_955EB0-zlmp1_wKRUhZf-Pf-2MaHWepAMOhM0M",
        alt: "Team Member",
      },
      description:
        "Chai's 3️⃣ obsessions - beer, coffee and Lego Batman.When he isn't downing pints of beer and shots of espresso, or solving a lego puzzle, he's fighting for the users. Just like Tron.",
      instagram: "https://instagram.com/chaitanyanettem",
      linkedin: "https://linkedin.com/in/cnettem/",
      github: "https://github.com/chaitanyanettem",
      twitter: "https://twitter.com/arrayjumper",
    },
    {
      name: "Vinita Sharma",
      job: "Engineering (Teams, Tech)",
      image: {
        url:
          "https://lh3.googleusercontent.com/s0JsY3-m9kCOd83LbadCBUNxQ_m-gJAK-aOkQBK1q6HsN_5NAV-3OQ5TZ_bGXTPFY35v0HQNBxmq7liBIXr_gOM",
        mobileURL:
          "https://lh3.googleusercontent.com/s0JsY3-m9kCOd83LbadCBUNxQ_m-gJAK-aOkQBK1q6HsN_5NAV-3OQ5TZ_bGXTPFY35v0HQNBxmq7liBIXr_gOM",
        alt: "Team Member",
      },
      description:
        "Lover of 🌱plants, 🐶doggos, 🏃running. When Vini is not pulling anyone’s leg, she’s purposefully rallying up the young team and moving them towards their goals, once step at a time.",
      instagram: "https://www.instagram.com/_viniitaa_/",
      github: "https://github.com/vinita77",
      linkedin: "https://www.linkedin.com/in/vinita-sharma-56887937/",
      twitter: "https://twitter.com/vinita_sharma77",
    },
    {
      name: "Chandan Kumar",
      job: "Engineering (Thinker, Tech)",
      image: {
        url:
          "https://lh3.googleusercontent.com/Kggb2B3zb54AKrYku2ggc2inP2uKslQmN8yZeZ5G2sub29aQDUeWb705i14n-WExEoAxYv6akrEi1jDlV6XtT2c",
        mobileURL:
          "https://lh3.googleusercontent.com/Kggb2B3zb54AKrYku2ggc2inP2uKslQmN8yZeZ5G2sub29aQDUeWb705i14n-WExEoAxYv6akrEi1jDlV6XtT2c",
        alt: "Team Member",
      },
      description:
        "Chandan is a self-confessed contrarian, but does enjoy healthy doses of cooking for family, listening to retro music and ⚽watching Man U win. He’s our resident ‘Search’ maestro.",
      linkedin: "https://www.linkedin.com/in/chandany5/",
      twitter: "https://twitter.com/chandany5",
    },
    {
      name: "Shree Vardhan",
      job: "Growth (Solutions, Growth)",
      image: {
        url:
          "https://lh3.googleusercontent.com/CAff4tqxyPSor9J1TCEvQQDqlEdg9mgVuFrysFfZa190CPWnyF6o3AHa6KX7r9a8V4XHYv_aRuppwxC7ieJP_gwr",
        mobileURL:
          "https://lh3.googleusercontent.com/CAff4tqxyPSor9J1TCEvQQDqlEdg9mgVuFrysFfZa190CPWnyF6o3AHa6KX7r9a8V4XHYv_aRuppwxC7ieJP_gwr",
        alt: "Team Member",
      },
      description:
        'Shree enjoys meeting new people - you\'ll often find grabbing cold coffee at the airport ✈️with a new "friend" he just met. But his one true love? Unearthing and solving deep-rooted problems.',
      linkedin: "https://www.linkedin.com/in/shree-vardhan/",
      instagram: "https://www.instagram.com/shree_vrdhan/?hl=en",
    },
    {
      name: "Paul John",
      job: "Design (UX, Product)",
      image: {
        url:
          "https://media.kubric.io/api/assetlib/050a8657-1fc5-477b-b5f1-2788c00141fb.png",
        mobileURL:
          "https://media.kubric.io/api/assetlib/050a8657-1fc5-477b-b5f1-2788c00141fb.png",
        alt: "Paul John",
      },
      description:
        "Paul John, Paul, Paulie, John Paul - many names, one persona. Complex problems are his jam, Blinkist is his secret weapon. He wont bat an eyelid before ending your chances at Monopoly 🤺 but will also check in with you when you need a friend.",
      linkedin: "https://www.linkedin.com/in/paulvjohn/",
    },
  ],
  work: {
    introduction:
      "Do you challenge the space around you? Do you love making and tearing down things and remaking them until they are just perfect? Do you embrace different? \n\nHop over, catch us over one of our open maker workshops on Saturday or come talk to one of the members in the team. Join us in making something together, making something great. \n\nWe love creative spirits and people who are not afraid to do things differently. Feel the same way? Join us on our mission to make good design the foundation of great work everywhere.",
    positions: [
      {
        index: 1,
        title: "📝  Social Content Marketing @ Kubric",
        content:
          "At Kubric we're looking for that Hybrid Social Media New-Age Marketer. You’re a content creator yes, but also an information curator. Content is the biggest asset you have for social media – it’s your fuel and it’s basically the value that you add to the environment.",
      },
      {
        index: 3,
        title: "🕸  Web Engineering Lead",
        content:
          "Kubric.io is building a modern AI powered workspace for Designers and Video Makers. We are looking for a Web engineer with 2-3 years of experience and strong grasp in React / Redux / Node. Major responsibilities for this position include building different tooling which is responsive, mobile.",
      },
    ],
  },
  artboard: [
    {
      title: "What we do",
      style: "dos",
      points: [
        "Stay Creative",
        "Provide Candid Critique",
        "Meme-ify Ourselves",
        "Love MarioKart",
        "Trust Good Design",
      ],
    },
    {
      title: "What we do not",
      style: "donts",
      points: [
        "Put the I before Us",
        "Make do with half-done",
        "Prevent dissenting voices",
        "Wait for instructions",
        "Prioritize growth over people",
      ],
    },
  ],
}

export default () => {
  const [showIndex, setShowIndex] = useState(0)
  const baseData = useStaticQuery(graphql`
  {
    allContentfulJobPost {
      edges {
        node {
          id
          title
          slug
          category
          description{
            description
          }
          linkToAngelList
        }
      }
    }

    allContentfulCoworker(sort: {fields: [employeeCode], order: ASC}) {
      edges {
        node {
          id
          name
          department
          responsibleFor
          description {
            json 
          }
          profilePhoto {
            file {
              url
            }
          }
        }
      }
    }

  }
`)

  // console.log(baseData)
  return (
    <Layout>
      <SEO
        title="Join our team | Kubric - All In One Workspace for Designers"
        description="Come build the next generation creative stack for designers and video makers."
      />
      <div className={"container"}>
        <div className={"headerContent"}>
          <h1 className={"heading"}>{data.heading}</h1>

          <div className={"content"}>
            <div className={"teamDescription"}>
              <p className={"description"}>{data.introduction.description}</p>
              <a href="#openPosts">
                <button className={"positionsButton"} id={"open-position-bttn"}>
                  View Open Positions
                </button>
              </a>
              <ResponsiveImage
                className={"teamImage"}
                image={data.introduction.image}
              />
            </div>
            <p className={"teamDes"}>{data.introduction.teamDes}</p>
          </div>
        </div>

        <ResponsiveImage className={"macIm"} image={data.introduction.image} />

        <div className={"metadata"}>
          {data.trivia.map(item => (
            <MetaData
              text={item.text}
              key={item.text}
              textStyle={"triviaText"}
              champ={item.champ}
              iconStyle={`${item.type} ${"triviaIcons"}`}
              type={item.type}
            />
          ))}
        </div>

        <div className={"team"}>
          <div className={"introduction"}>
            <h1>The Crew</h1>
            <br />
            <p>{data.crewDescription}</p>
          </div>
          {
            baseData.allContentfulCoworker.edges.map(member => <CrewMember member={member.node} key={member.node.id} />)
          }
        </div>

        <div id="openPosts" className={"workContainer"}>
          <div className={"intro"}>
            <h1>Work With Us</h1>
            <p>{data.work.introduction}</p>
          </div>
          <div className={"positions"}>
            <p className={"positionsHeading"}>OPEN POSITIONS</p>
            {
              baseData.allContentfulJobPost.edges.map(post => <Accordion
                key={post.node.id} onSelect={() => {
                  setShowIndex(post.node.id)
                }}
                showContent={showIndex === post.node.id ? true : false}
                title={post.node.title}
                category={post.node.category}>
                <p className={"positionsContent"}><ReactMarkdown>{post.node.description.description}</ReactMarkdown></p>
                <a href={post.node.linkToAngelList} target="_blank">
                  <button
                    className={"positionButton"}
                    id={`apply-now-${post.node.title}`}
                  >
                    Apply Now
                      </button>
                </a>
              </Accordion>)
            }
          </div>
        </div>

        <div className={"artboard"}>
          {data.artboard.map(item => (
            <div className={"board"} key={item.title}>
              <p className={"boardTitle"}>{item.title}</p>
              {item.points.map(point => (
                <p key={point} className={"boardPoint"}>
                  {point}
                </p>
              ))}
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}
